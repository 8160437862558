import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Highlight from 'react-highlight';
import { homeCode } from './code';

export default function Home() {
  useEffect(() => {
    document.title = 'Demo | Home';
  }, []);
 
  return (
    <div>
      <Link to="replace">Go to replace</Link>

      <Highlight language="javascript">
        {homeCode}
      </Highlight>
    </div>
	
  )
}

