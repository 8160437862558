
export const replaceCode = `
const ReplaceModeComponent = () => {
  const navigate = useNavigate()
  
  window.unload = function() { }

  useEffect(() => {
    console.log('Called replace component !');
    document.title = 'Demo | Replace';
    window.location.replace(url);
  }, []);
  
  return (
    <div>
      <span>You will be redirected to </span><a href={url}>{url}</a>
      <Highlight language="javascript">
       {replaceCode}
      </Highlight>
    </div>
  );
};


export default ReplaceModeComponent;
`

export const homeCode = `
export default function Home() {
  useEffect(() => {
    document.title = 'Demo | Home';
  }, []);
 
  return (
    <div>
      <Link to="replace">Go to replace</Link>

      <Highlight language="javascript">
        {homeCode}
      </Highlight>
    </div>
	
  )
}
`
