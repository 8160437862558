import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home'
import Replace from './Replace'

function App() {
  return (  	
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/replace" element={<Replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
