import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Highlight from 'react-highlight'
import { replaceCode } from './code'

const url = "https://tympanus.net/codrops/"

const ReplaceModeComponent = () => {
	
  const navigate = useNavigate()
  
  const handleUnload = () => console.log('unloading page');

  useEffect(() => {
      window.addEventListener("beforeunload", handleUnload);
      return () => {
        window.removeEventListener("beforeunload", handleUnload);
      };
    }, []);

  useEffect(() => {
    console.log('Called replace component !');
    document.title = 'Demo | Replace';
    window.location.replace(url);
  }, []);
  
  return (
    <div>
      <span>You will be redirected to </span><a href={url}>{url}</a>
      <Highlight language="javascript">
       {replaceCode}
      </Highlight>
    </div>
  );
};


export default ReplaceModeComponent;

